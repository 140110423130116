import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/home",
    redirect: "/",
  },
  {
    path: "/join",
    name: "Join",
    component: () => import("../views/Join.vue"),
  },
  {
    path: "/server",
    name: "Server",
    component: () => import("../views/Server.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/call",
    name: "Call",
    component: () => import("../views/Call.vue"),
  },
  {
    path: "/netErr",
    name: "NetErr",
    component: () => import("../views/netErr.vue"),
  },
  {
    path: "/download",
    name: "Download",
    component: () =>import("../views/Download.vue"),
  },
  {
    path: "/downloadTest",
    name: "DownloadTest",
    component: () =>import("../views/DownloadTest.vue"),
  },
  {
    path: "/code",
    name: "Code",
    component: () =>import("../views/Code.vue"),
  },
  {
    path:"*",
    redirect:"/netErr"
  }
];

const vueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return vueRouterPush.call(this, to).catch(err => err)
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;