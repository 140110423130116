<template>
  <div class="foot bgcolor2">
    <div class="main">
      <div class="left-block">
        <div class="left-bg"></div>
        <p>联系电话：18050272510</p>
      </div>
      <div class="right clearfix">
        <div class="right-block">
          <p
            style="
              font-size: 17px;
              font-weight: bold;
              color: #ffffff;
              line-height: 18px;
              margin-bottom: 19px;
            "
          >产品</p>
          <p
            style="
              font-size: 12px;
              line-height: 15px;
              height: 10px;
              margin-bottom: 13px;
              color: #ccc;
              cursor: pointer;
            "
            @click="goDetail(3)"
          >数智云助贷业务管理平台</p>
          <p
            style="
              font-size: 12px;
              line-height: 15px;
              height: 10px;
              margin-bottom: 13px;
              color: #ccc;
              cursor: pointer;
            "
            @click="goDetail(1)"
          >数智云智能风控系统</p>
          <p
            style="
              font-size: 12px;
              line-height: 15px;
              height: 10px;
              margin-bottom: 13px;
              color: #ccc;
              cursor: pointer;
            "
            @click="goDetail(2)"
          >数智云车资产管理系统</p>
        </div>
        <div class="right-block">
          <p
            style="
              font-size: 17px;
              font-weight: bold;
              color: #ffffff;
              line-height: 18px;
              margin-bottom: 19px;
            "
          >服务与支持</p>
          <p
            style="
              font-size: 12px;
              line-height: 15px;
              height: 10px;
              margin-bottom: 13px;
              color: #ccc;
              cursor: pointer;
            "
            @click="goJoin"
          >咨询服务</p>
          <p
            style="
              font-size: 12px;
              line-height: 15px;
              height: 10px;
              margin-bottom: 13px;
              color: #ccc;
              cursor: pointer;
            "
            @click="goJoin"
          >实施服务</p>
          <p
            style="
              font-size: 12px;
              line-height: 15px;
              height: 10px;
              margin-bottom: 13px;
              color: #ccc;
              cursor: pointer;
            "
            @click="goJoin"
          >培训服务</p>
        </div>
        <div class="right-block">
          <p
            style="
              font-size: 17px;
              font-weight: bold;
              color: #ffffff;
              line-height: 18px;
              margin-bottom: 19px;
            "
          >服务与支持</p>
          <p
            style="
              font-size: 12px;
              line-height: 15px;
              height: 10px;
              margin-bottom: 13px;
              color: #ccc;
              cursor: pointer;
            "
            @click="goJoin"
          >申请试用</p>
          <p
            style="
              font-size: 12px;
              line-height: 15px;
              height: 10px;
              margin-bottom: 13px;
              color: #ccc;
              cursor: pointer;
            "
            @click="goJoin"
          >联系我们</p>
        </div>
      </div>
    </div>
    <!-- <div class="beian"> -->
    <!-- <span
        ><a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35011102350898"
          target="_blank"
          >Copyright © 2022 福州数智云控信息科技有限公司. All rights reserved
          版权所有 |
          <span
            ><img
              src="../assets/gvn_log.png"
              style="width: 13px; height: 13px; margin-right: 3px" /></span
          >闽公网安备 35011102350898号</a
        ></span
    >-->
    <div class="beian">
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35011102350898"
        style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
      >
        <p style="float: left;margin-right: 5px;">
          Copyright © 2022 福州数智云控信息科技有限公司. All rights reserved
          版权所有 |
        </p>
        <img src="../assets/gvn_log.png" style="float: left" />
        <p
          style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
        >闽公网安备 35011102350898号</p>
      </a>
      <a
        target="_blank"
        href="https://beian.miit.gov.cn/?vt=4&pos=108&his=0#/Integrated/recordQuery"
        style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
      >
        <p
          style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
        >闽ICP备2021008164号-1</p>
      </a>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: {
    index: Number
  },
  methods: {
    goDetail(e) {
      if (this.$route.query.flag == e) {
        return false;
      } else {
        this.$router.push({
          path: "/server",
          query: { flag: e }
        });
      }
    },
    goJoin() {
      if (this.$route.path == "/call") {
        window.scrollTo(0, 0);
      } else {
        this.$router.push({
          path: "/call"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.foot {
  width: 100%;
  min-width: 1200px;
  height: 189px;
}
.bgcolor1 {
  background: #081a30;
}
.bgcolor2 {
  background: #100f0d;
}
.main {
  width: 1200px;
  height: 148px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
}
.beian {
  // width: 800px;
  // margin: 0 auto;
  // padding: 10px 0;
  height: 40px;
  border-top: 1px solid #333333;
  text-align: center;
  font-size: 10px;
  color: #fff;
  line-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-block {
  display: inline-block;
  width: 288px;
  height: 100px;
  box-sizing: border-box;
  border-right: 1px solid #fff;
  margin-top: 24px;
  padding-top: 10px;
}
.left-bg {
  width: 120px;
  height: 40px;
  background: url("../assets/pic/logo.png") no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: 18px;
}
.left-block > p {
  text-align: center;
  font-size: 15px;
  color: #ffffff;
}
.right {
  padding-top: 30px;
  margin-left: 118px;
}
.right-block {
  float: left;
  margin-right: 160px;
}
</style>
