<template>
  <div class="nav clearfix">
    <div class="navbg fl"></div>
    <div class="fr navtabs">
      <div class="fr tabs clearfix">
        <div
          :class="['fl', currentIndex == index ? 'active' : '']"
          v-for="(item, index) in nav"
          :key="item.text"
          @click="getTo(item, index)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: [
        { text: "首页", path: "/" },
        { text: "关于我们", path: "/about" },
        { text: "产品服务", path: "/server" },
        { text: "人才招聘", path: "/join" },
        { text: "联系我们", path: "/call" },
      ],
      currentIndex: 0,
    };
  },
  watch: {
    $route: "getPath",
  },
  methods: {
    getTo(item, index) {
      this.$router.push(item.path);
      this.currentIndex = index;
      this.$emit("tabs", index);
    },
    getPath(to, from) {
      console.log(to, from);
      if (to.path == "/") {
        this.currentIndex = 0;
      }
      if (to.path == "/about") {
        this.currentIndex = 1;
      }
      if (to.path == "/server") {
        this.currentIndex = 2;
      }
      if (to.path == "/join") {
        this.currentIndex = 3;
      }
      if (to.path == "/call") {
        this.currentIndex = 4;
      }
      if (to.path == "/netErr") {
        this.currentIndex = 5;
        this.$emit("tabs", 5);
      }
      if (to.path == "/Download") {
        this.$emit("tabs", 5);
      }
      if (to.path == "/Code") {
        this.$emit("tabs", 5);
      }
      if (to.path == "/DownloadTest") {
        this.$emit("tabs", 5);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  width: 1000px;
  min-width: 900px;
  margin: 0 auto;
  height: 60px;
  float: left;
  padding-top: 20px;
  /* position: fixed;
  top: 40px;
  left: 50%;
  transform: translateX(-50%); */
}
.navbg {
  width: 120px;
  height: 40px;
  background: url("../assets/pic/logo.png") no-repeat;
  background-size: 100% 100%;
}
.navtabs {
  margin-top: 12px;
  width: auto;
  height: auto;
}
.tabs > div {
  height: 35px;
  font-size: 14px;
  color: #ccc;
  margin-left: 70px;
  cursor: pointer;
  position: relative;
}

.active::after {
  content: "";
  display: block;
  width: 30px;
  height: 4px;
  background: linear-gradient(90deg, #022ee5 0%, #22cdfc 100%);
  border-radius: 2px;
  margin: 5px auto;
}
</style>
