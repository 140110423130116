<template>
  <div id="app">
    <div v-show="show">
      <div class="center">
        <navhead v-on:tabs="getIndex"></navhead>
      </div>
      <router-view />
      <pagesfooter :index="index"></pagesfooter>
    </div>
    <div class="nodata" v-show="!show">
      <router-view />
    </div>
  </div>
</template>
<script>
import navhead from "./components/navhead.vue";
import pagesfooter from "./components/pagesfooter.vue";
export default {
  components: {
    navhead,
    pagesfooter,
  },
  data() {
    return {
      index: 0,
      show: true,
    };
  },
  methods: {
    getIndex(e) {
      this.index = e;
      console.log(this.index);
      this.$nextTick(() => {
        if (e == 5) {
          this.show = false;
        } else {
          this.show = true;
        }
      });
    },
  },
};
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100vh;
}
.nodata{
  width: 100%;
  height: 100%;
}
.center {
  width: 1000px;
  margin: 0 auto;
}
</style>
